<template>
  <div class="main-content white">
    <div class="container">
      <ul class="group-manage-menu">
        <li>
          <div class="step">
            Step.1
            <span>增減單作業及改價作業可以不必執行</span>
          </div>
          <router-link :to="{ name: 'CreatedGroupManageReduced' }">
            增減單作業
          </router-link>
        </li>
        <li>
          <div class="step">Step.2 {{ finishedStep2 ? '(已完成)' : '' }}</div>
          <a :class="[step2BtnClass]" @click="purchasAction">
            採購作業
          </a>
        </li>
        <li>
          <div class="step">Step.3</div>
          <router-link :to="{ name: 'CreatedGroupManageChangePrice' }">
            改價作業
          </router-link>
        </li>
        <li>
          <div class="step">Step.4</div>
          <!--鎖定 lock, 放行 access-->
          <a
            :class="[canSetValidGroup ? 'access' : 'lock']"
            @click.prevent="setValidGroup"
          >
            成團通知
          </a>
        </li>
      </ul>
    </div>
    <div
      class="modal fade"
      id="modal-helper-ways"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delalert"
    >
      <div class="modal-dialog modal-sm modal-helper">
        <div class="modal-content">
          <div class="modal-title">採購作業</div>
          <div class="helper-content">是否要在網站上下訂？</div>
          <div class="modal-btn-block">
            <a
              class="modal-btn cancel"
              :data-order-from="2"
              @click="purchasePage"
            >
              不同意
            </a>
            <a class="modal-btn" :data-order-from="1" @click="purchasePage">
              同意
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupBuyingManageInfo, setValidGroup } from '@/api/group-buying';
import { get } from 'lodash';

export default {
  data() {
    return {
      haveToFinishedStep2:
        process.env.VUE_APP_HAVE_TO_FINISHED_STEP2 === 'true',
      group: null
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupFromWebsite() {
      return get(this.group, 'from_website') === 1 ? true : false;
    },
    finishedStep2() {
      return get(this.group, 'finished_step2') === 1 ? true : false;
    },
    groupStatus() {
      return get(this.group, 'status');
    },
    isValidGroup() {
      return this.groupStatus === 3;
    },
    canSetValidGroup() {
      return this.finishedStep2 || this.haveToFinishedStep2 === false;
    },
    step2BtnClass() {
      let basic = this.haveToFinishedStep2 ? 'access' : '';
      return this.finishedStep2 ? 'lock' : basic;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.setData();
      }
    },
    groupStatus: {
      immediate: true,
      handler(val) {
        if (this.isValidGroup) {
          // this.sendSuccessNotification();
        }
      }
    }
  },
  methods: {
    setData() {
      getGroupBuyingManageInfo(this.groupId)
        .then(data => {
          this.group = data;
        })
        .catch(() => {
          this.$router.push({
            name: 'CreatedGroupList'
          });
        });
    },
    sendSuccessNotification() {
      this.$router.push({
        name: 'CreatedGroupNotification',
        params: {
          group_id: this.groupId,
          type: 'valid-notification'
        }
      });
    },
    setValidGroup() {
      if (this.canSetValidGroup === false) {
        return;
      }

      setValidGroup({
        group_buying_order_id: this.groupId
      }).then(data => {
        this.sendSuccessNotification();
      });
    },
    purchasAction(e) {
      this.groupFromWebsite ? this.purchaseDialog() : this.purchasePage(e);
    },
    purchasePage(e) {
      $('#modal-helper-ways').modal('hide');

      this.$router.push({
        name: 'CreatedGroupManagePurchase',
        params: {
          group_id: this.groupId
        },
        query: {
          from_website: get(this.group, 'from_website'),
          order_from: e.target.dataset.orderFrom //1: 向網站訂購 2: 自己處理
        }
      });
    },
    purchaseDialog() {
      if (this.finishedStep2) {
        return;
      }

      $('#modal-helper-ways').modal('show');
    }
  }
};
</script>
